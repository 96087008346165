import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/pro-light-svg-icons';

import { InputFormGroup, SelectFormGroup, PhoneFormGroup } from 'bundles/components/common/Form';
import { isRequired, isValidEmail, isSelectRequired, isValidPhoneNumber, isUserRegistered } from 'bundles/components/common/Form/validations';
import If from 'bundles/components/common/If';
import { URL } from 'bundles/constants';
import { Step } from 'bundles/components/common/Steps';
import Loader from 'bundles/components/common/Loader';
import { useMerchantRegistrationFirstStep } from 'bundles/hooks';
import Recaptcha from 'bundles/components/common/Recaptcha';

const MerchantRegistrationFormFirstStep = () => {
  const { t } = useTranslation();
  const { register, errors, state, control, setValue, watchedCountryId, handleSubmitSignUp, isSubmitted } = useMerchantRegistrationFirstStep();

  return (
    <Card>
      <Card.Header as={'h1'} className="border-0">{t('public.titles.business_account') as string}</Card.Header>

      <Card.Body>
        <Form className="js-no-disable-submit-form">
          <div className="form-section-title mt-0">{t('public.labels.personal')}</div>

          <Row>
            <Col xl={6}>
              <InputFormGroup
                name="merchant_registration[authorized_business_representative_attributes][first_name]"
                label={t('labels.name')}
                register={register}
                validate={{ isRequired }}
                errors={errors}
                maxLength={50}
                autoComplete="off"
              />
            </Col>
            <Col xl={6}>
              <InputFormGroup
                name="merchant_registration[authorized_business_representative_attributes][last_name]"
                label={t('labels.last_name')}
                register={register}
                validate={{ isRequired }}
                errors={errors}
                maxLength={50}
                autoComplete="off"
              />
            </Col>
            <Col xl={6}>
              <InputFormGroup
                name="merchant_registration[authorized_business_representative_attributes][email]"
                label={t('labels.email')}
                register={register}
                validate={{ isRequired, isValidEmail, isUserRegistered: value => isUserRegistered(value, state.errorFields?.email?.value, state.errorFields?.email?.message) }}
                errors={errors}
                maxLength={250}
                autoComplete="off"
              />
            </Col>
            <Col xl={6}>
              <InputFormGroup
                name="merchant_registration[authorized_business_representative_attributes][position]"
                label={t('labels.position')}
                register={register}
                validate={{ isRequired }}
                errors={errors}
                maxLength={250}
                autoComplete="off"
              />
            </Col>
          </Row>

          <div className="form-section-title">{t('labels.address')}</div>

          <Row>
            <Col xl={3}>
              <SelectFormGroup
                label={t('labels.country_of_residence')}
                name="merchant_registration[authorized_business_representative_attributes][address_attributes][country_id]"
                register={register}
                options={state.countries}
                validate={{ isSelectRequired }}
                errors={errors}
              />
            </Col>
            <Col xl={3}>
              <InputFormGroup
                label={`${t('labels.state')} <i class="optional">(${t('labels.optional')})</i>`}
                name="merchant_registration[authorized_business_representative_attributes][address_attributes][state]"
                maxLength={35}
                autoComplete="off"
                register={register}
              />
            </Col>
            <Col xl={3}>
              <InputFormGroup
                label={t('labels.postcode')}
                name="merchant_registration[authorized_business_representative_attributes][address_attributes][postcode]"
                autoComplete="off"
                register={register}
                validate={{ isRequired }}
                errors={errors}
              />
            </Col>
            <Col xl={3}>
              <InputFormGroup
                label={t('labels.city')}
                name="merchant_registration[authorized_business_representative_attributes][address_attributes][city]"
                minLength={1}
                maxLength={35}
                autoComplete="off"
                register={register}
                validate={{ isRequired }}
                errors={errors}
              />
            </Col>
            <Col xl={6}>
              <PhoneFormGroup
                name="merchant_registration[authorized_business_representative_attributes][address_attributes][phone_number]"
                label={t('labels.phone_number')}
                register={register}
                control={control}
                validate={{ isRequired, isValidPhoneNumber }}
                errors={errors}
              />
            </Col>
            <Col xl={6}>
              <InputFormGroup
                label={t('labels.address_line_1')}
                name="merchant_registration[authorized_business_representative_attributes][address_attributes][address_line_1]"
                maxLength={95}
                autoComplete="off"
                register={register}
                validate={{ isRequired }}
                errors={errors}
              />
            </Col>
            <Col xl={6}>
              <InputFormGroup
                label={`${t('labels.address_line_2')} <i class="optional">(${t('labels.optional')})</i>`}
                name="merchant_registration[authorized_business_representative_attributes][address_attributes][address_line_2]"
                maxLength={95}
                autoComplete="off"
                register={register}
              />
            </Col>
            <Col xl={6}>
              <SelectFormGroup
                label={t('labels.country_of_birth')}
                name="merchant_registration[authorized_business_representative_attributes][country_of_birth_id]"
                register={register}
                options={state.countries}
                validate={{ isSelectRequired }}
                errors={errors}
              />
            </Col>
            <Col xl={6}>
              <SelectFormGroup
                label={t('labels.country_of_citizenship')}
                name="merchant_registration[authorized_business_representative_attributes][country_of_nationality_id]"
                register={register}
                options={state.countries}
                validate={{ isSelectRequired }}
                errors={errors}
              />
            </Col>
            <Col xl={6}>
              <Form.Label className="required">{t('labels.date_of_birth')}</Form.Label>
              <Row>
                <Col xl={4}>
                  <SelectFormGroup
                    name="merchant_registration[authorized_business_representative_attributes][date_of_birth(3i)]"
                    register={register}
                    options={state.dob_day_range}
                    disabledWithSkipValidation={!watchedCountryId}
                    validate={{ isSelectRequired }}
                    errors={errors}
                  />
                </Col>
                <Col xl={4}>
                  <SelectFormGroup
                    name="merchant_registration[authorized_business_representative_attributes][date_of_birth(2i)]"
                    register={register}
                    disabledWithSkipValidation={!watchedCountryId}
                    options={state.dob_month_range}
                    validate={{ isSelectRequired }}
                    errors={errors}
                  />
                </Col>
                <Col xl={4}>
                  <SelectFormGroup
                    name="merchant_registration[authorized_business_representative_attributes][date_of_birth(1i)]"
                    register={register}
                    disabledWithSkipValidation={!watchedCountryId}
                    options={state.dob_year_range}
                    validate={{ isSelectRequired }}
                    errors={errors}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <If condition={state.recaptcha_key}>
                  <Recaptcha sitekey={state.recaptcha_key} register={register} setValue={setValue} refreshReCaptcha={state.refreshReCaptcha} action="merchant_registration" />
                </If>
              </Form.Group>
            </Col>
          </Row>

          <input type="hidden" {...register('step_number')} />

          <Step.Next onClickNext={handleSubmitSignUp} className="float-right btn-next">
            <Loader url={isSubmitted ? URL.MERCHANTS_REGISTRATIONS: ''} className={'text-center loading--over loading--with-fog'} />
            {t('labels.continue')}
            <FontAwesomeIcon icon={faArrowRightLong} className="ml-4" size="lg" />
          </Step.Next>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default MerchantRegistrationFormFirstStep;
