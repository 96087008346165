import React, { useState, SyntheticEvent, useEffect } from 'react';
import { FieldValues, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

import { isValidEmail, isRequired, isValidPassword, isEqualValues } from 'bundles/components/common/Form/validations';
import { InputFormGroup, InputGroupFormGroup } from 'bundles/components/common/Form';
import PasswordIcons from 'bundles/components/common/PasswordIcons';
import { formUtils, urlUtils } from 'bundles/utils';
import Recaptcha from 'bundles/components/common/Recaptcha';
import { SetPasswordFormValues, SetPasswordFormProps, ISetPassowrd } from './types';
import { URL } from 'bundles/constants';

const SetPasswordForm = ({ id, email, recaptcha_key }: SetPasswordFormProps) => {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState<ISetPassowrd>({ passwordVisible: false, confirmPasswordVisible: false });
  const { control, register, handleSubmit, formState: { errors, isSubmitted, isSubmitting }, setValue, trigger, getValues } = useForm<FieldValues>({
    defaultValues: { email:  email, _method: 'put', authenticity_token: window.csrfToken }
  });

  const watchPassword = useWatch({ name: 'new_password', control });
  const watchPasswordConfirmation = useWatch({ name: 'new_password_confirmation', control });

  const onSubmitForm = async (fd: SetPasswordFormValues, e: SyntheticEvent) => (e.target as HTMLFormElement).submit();

  useEffect(() => {
    if (isSubmitted && Boolean(watchPasswordConfirmation) && watchPassword === watchPasswordConfirmation) trigger('new_password_confirmation');
  }, [watchPassword]);

  return (
    <Form onSubmit={handleSubmit(onSubmitForm)} action={`/${i18n.language}${URL.RESET_PASSWORD}/${urlUtils.getLastSegment(id)}`}  method="post" noValidate>
      <input type="hidden" {...register('_method')} />
      <input type="hidden" {...register('authenticity_token')} />

      <InputFormGroup
        type="email"
        name="email"
        label={t('labels.email_address')}
        register={register}
        disabled={true}
        validate={{ isRequired, isValidEmail }}
        errors={errors}
      />

      <InputGroupFormGroup
        name="new_password"
        label={t('labels.new_password')}
        register={register}
        validate={{ isRequired, isValidPassword }}
        errors={errors}
        onPaste={(e: SyntheticEvent) => e.preventDefault()}
        classNameGroup={'form-group-with-info-icon'}
        className="border-right-0"
        autoComplete="off"
        type={formUtils.toggleInputType(state.passwordVisible)}
        appendLabel={(
          <FontAwesomeIcon
            icon={state.passwordVisible ? faEyeSlash : faEye}
            size="lg"
            role="button"
            className="text-gray mx-2"
            onClick={() => setState(prevState => ({ ...prevState, passwordVisible: !prevState.passwordVisible }))}
          />
        )}
        render={() => <PasswordIcons />}
      />

      <InputGroupFormGroup
        name="new_password_confirmation"
        label={t('labels.confirm_new_password')}
        register={register}
        validate={{ isRequired, isValidPassword, isEqualValues: (v: string) => isEqualValues(v, getValues('new_password'))  }}
        errors={errors}
        onPaste={(e: SyntheticEvent) => e.preventDefault()}
        classNameGroup={'form-group-with-info-icon'}
        className="border-right-0"
        autoComplete="off"
        type={formUtils.toggleInputType(state.confirmPasswordVisible)}
        appendLabel={(
          <FontAwesomeIcon
            icon={state.confirmPasswordVisible ? faEyeSlash : faEye}
            size="lg"
            role="button"
            className="text-gray mx-2"
            onClick={() => setState(prevState => ({ ...prevState, confirmPasswordVisible: !prevState.confirmPasswordVisible }))}
          />
        )}
        render={() => <PasswordIcons />}
      />

      <Recaptcha sitekey={recaptcha_key} register={register} setValue={setValue} action="reset_password" />

      <input value={t('labels.submit')} className="btn btn-primary btn-block" disabled={isSubmitting} type="submit" />
    </Form>
  );
};

export default SetPasswordForm;
