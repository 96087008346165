import 'stylesheets/public.scss';

import 'javascripts/public/resendEmail';

// REACT
import ReactOnRails from 'react-on-rails';
import 'bundles/utils/i18n';

// REACT/COMPONENTS/COMMON
import CookieConsent from './bundles/components/common/CookieConsent';
import PhoneNumber from './bundles/components/common/Form/PhoneNumber';
import PublicFooter from './bundles/components/common/public/Footer';
import PublicHeader from './bundles/components/common/public/Header';

// REACT/VIEWS/PUBLIC
import AboutUsView from './bundles/views/public/AboutUs/AboutUsView';
import AffiliateView from './bundles/views/public/Affiliate/AffiliateView';
import BusinessView from './bundles/views/public/Business/BusinessView';
import ConsumerRegistrationView from './bundles/views/public/ConsumerRegistration/ConsumerRegistrationView';
import ConsumerTermsConditionsView from './bundles/views/public/ConsumerTermsConditions/ConsumerTermsConditionsView';
import CookieSettingsView from './bundles/views/public/Cookies/CookieSettingsView';
import DocumentationHistoryView from './bundles/views/public/DocumentationHistory/DocumentationHistoryView';
import FaqView from './bundles/views/public/Faq/FaqView';
import FeesAndLimitsView from './bundles/views/public/FeesAndLimits/FeesAndLimitsView';
import ForbiddenPageView from './bundles/views/errorPages/ForbiddenPageView';
import ForgotPasswordView from './bundles/views/public/ForgotPassword/ForgotPasswordView';
import HomeView from './bundles/views/public/Home/HomeView';
import MerchantRegistrationView from './bundles/views/public/MerchantRegistration/MerchantRegistrationView';
import InternalErrorView from './bundles/views/errorPages/InternalErrorView';
import PageNotFoundView from './bundles/views/errorPages/PageNotFoundView';
import PersonalView from './bundles/views/public/Personal/PersonalView';
import PrivacyPolicyView from './bundles/views/public/PrivacyPolicy/PrivacyPolicyView';
import ReferFriendView from './bundles/views/public/ReferFriend/ReferFriendView';
import SetPasswordView from './bundles/views/public/SetPassword/SetPasswordView';
import SignInView from './bundles/views/public/SignIn/SignInView';
import TermsOfUseView from './bundles/views/public/TermsOfUse/TermsOfUseView';
import VerifySignInView from './bundles/views/public/VerifySignIn/VerifySignInView';
import VerifyPhoneNumberView from './bundles/views/public/VerifyPhoneNumber/VerifyPhoneNumberView';
import VipView from './bundles/views/public/Vip/VipView';

// REACT/REGISTER/COMPONENTS/COMMON
ReactOnRails.register({
  CookieConsent,
  PhoneNumber,
  PublicFooter,
  PublicHeader
});

// REACT/REGISTER/PUBLIC
ReactOnRails.register({
  AboutUsView,
  AffiliateView,
  BusinessView,
  ConsumerRegistrationView,
  ConsumerTermsConditionsView,
  CookieSettingsView,
  DocumentationHistoryView,
  FaqView,
  FeesAndLimitsView,
  ForbiddenPageView,
  ForgotPasswordView,
  HomeView,
  InternalErrorView,
  MerchantRegistrationView,
  PageNotFoundView,
  PersonalView,
  PrivacyPolicyView,
  ReferFriendView,
  SetPasswordView,
  SignInView,
  TermsOfUseView,
  VerifyPhoneNumberView,
  VerifySignInView,
  VipView
});
