import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/pro-light-svg-icons';

import { publicImages } from 'bundles/utils/index';
import If from 'bundles/components/common/If/index';

import './styles.scss';

const Title = ({ title, subTitle, btnLabel, btnUrl, showPartners = true }: Record<string, any>) =>
  <div className="text-left">
    <div className="mt-lg-8">
      <h1>{title}</h1>
      <p>{subTitle}</p>

      <If condition={btnUrl}>
        <a className="btn btn-primary register-btn mt-6" href={btnUrl}>{btnLabel}
          <FontAwesomeIcon className="ml-4" icon={faArrowRightLong} size="lg" />
        </a>
      </If>
    </div>

    <If condition={showPartners}>
      <div className="billboard-partners mt-9 d-flex flex-wrap">
        <img alt="blik" src={publicImages.blik} />
        <img alt="payid" src={publicImages.payid} />
        <img alt="neosurf" src={publicImages.neosurf} />
        <img alt="paysafecard" src={publicImages.paysafecard} />
      </div>
    </If>
  </div>;

export default Title;
