(function ($) {
    Wallet.ResendEmail = function (formSelector) {
        this.form = $(formSelector);
        this.init();
    };

    Wallet.ResendEmail.prototype = {
        init: function () {
          grecaptcha.enterprise.ready(async () => {
            const token = await grecaptcha.enterprise.execute($('#g-recaptcha-response').data("key"), {action: 'account_verification'});

            $('#g-recaptcha-response').val(token)
          });
        },
    };
})(jQuery);

jQuery(function () {
    Wallet.Utilities.guardWith(jQuery("#resendEmail"), function () {
        new Wallet.ResendEmail("#resendEmail");
    });
});
