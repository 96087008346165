import React, { FC } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Billboard from 'bundles/components/common/public/Billboard';
import { URL } from 'bundles/constants';
import { publicImages } from 'bundles/utils';
import RegisterBanner from 'bundles/components/common/public/RegisterBanner';
import If from 'bundles/components/common/If';
import JoinVipClub from 'bundles/components/common/public/JoinVipClub';

import './styles.scss';

const HomeView = ({ is_user_signed_in, inline_message } : {is_user_signed_in: string; inline_message: string}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Billboard>
        <Row>
          <If condition={inline_message}>
            <Col sm={12}>
              <span className="mb-5 d-inline-block" dangerouslySetInnerHTML={{ __html: inline_message }} />
            </Col>
          </If>
          <Col xl={5} lg={6}>
            <Billboard.Title
              title={t('public.home.billboard.title')}
              subTitle={t('public.home.billboard.sub_title')}
              btnLabel={t('public.labels.get_started')}
              btnUrl={`/${i18n.language}${URL.CONSUMERS_NEW}`}
            />
          </Col>
          <Col xl={7} lg={6}>
            <img className="billboard-image" alt="mobile dashboard view" src={publicImages.recentTransactionsMobileView} />
          </Col>
        </Row>
      </Billboard>

      <Container>
        <section>
          <h2>{t('public.home.wallet_that_works_for_you.title')}</h2>
          <Row>
            <Col lg={6} xl={4} className="mb-6 mb-xl-0">
              <img className="feature-icon" alt="icon for choice" src={publicImages.checkWithCircle} />
              <h3 className="mt-2 mb-1">{t('public.home.wallet_that_works_for_you.choice.title')}</h3>
              {t('public.home.wallet_that_works_for_you.choice.content')}
            </Col>
            <Col lg={6} xl={4} className="mb-6 mb-xl-0">
              <img className="feature-icon" alt="icon for convenience" src={publicImages.screens} />
              <h3>{t('public.home.wallet_that_works_for_you.convenience.title')}</h3>
              {t('public.home.wallet_that_works_for_you.convenience.content')}
            </Col>
            <Col lg={6} xl={4}>
              <img className="feature-icon" alt="icon for control" src={publicImages.controls} />
              <h3 className="mt-2 mb-1">{t('public.home.wallet_that_works_for_you.control.title')}</h3>
              {t('public.home.wallet_that_works_for_you.control.content')}
            </Col>
          </Row>
        </section>
      </Container>

      <Container fluid className="billboard-aside billboard-aside-left billboard-aside-business-needs bg-secondary">
        <Container>
          <section>
            <Row className="justify-content-md-between">
              <Col xl={6} sm={12} className="order-xl-1 order-2">
                <img className="phones my-4" alt="eZeeWallet mobile app screens" src={publicImages.mobileScreens} />
              </Col>
              <Col xl={5} className="pt-3 pb-10 pl-xl-10 order-xl-2 order-1">
                <h2>{t('public.home.build_around_business_needs.title')}</h2>
                <p className="text-with-square-icon">{t('public.home.build_around_business_needs.easily_load')}</p>
                <p className="text-with-square-icon">{t('public.home.build_around_business_needs.streamlined_payment')}</p>
                <p className="text-with-square-icon mb-8">{t('public.home.build_around_business_needs.get_paid_quickly')}</p>

                <a href="https://apps.apple.com/ng/app/ezeewallet/id1456247737" className="mobile-operating-system-ios mr-3" />
                <a href="https://play.google.com/store/apps/details?id=com.ezeewallet.app" className="mobile-operating-system-android" />
              </Col>
            </Row>
          </section>
        </Container>
      </Container>

      <Container fluid className="billboard-aside billboard-aside-left bg-lighter-gray">
        <Container>
          <section>
            <JoinVipClub />
          </section>
        </Container>
      </Container>

      <If condition={!is_user_signed_in}>
        <Container>
          <section>
            <RegisterBanner subTitle={t('public.titles.banner.better_way_to_pay.sub_title.home')} />
          </section>
        </Container>
      </If>
    </>
  );
};

const HomeViewWrapper: FC<{is_user_signed_in: string; inline_message: string}> = props => <HomeView {...props} />;

export default HomeViewWrapper;
