import React, { useState, useEffect, ChangeEvent, SyntheticEvent } from 'react';
import { FieldValues, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

import { InputFormGroup, SelectFormGroup, InputGroupFormGroup } from 'bundles/components/common/Form';
import Recaptcha from 'bundles/components/common/Recaptcha';
import { isRequired, isValidEmail, isEqualValues, isValidPassword, isSelectRequired, isValidNameFormat } from 'bundles/components/common/Form/validations';
import If from 'bundles/components/common/If';
import axios, { AxiosResponse } from 'bundles/apis/defaults';
import { URL } from 'bundles/constants';
import PasswordIcons from 'bundles/components/common/PasswordIcons';
import { Step } from 'bundles/components/common/Steps';
import Loader from 'bundles/components/common/Loader';
import { setDobDisabled, setSubmitRequest } from './utils';
import { formUtils } from 'bundles/utils';
import { IConsumerRegistration }  from './types';

const ConsumerRegistrationForm = ({ onSubmitSignUp }: { onSubmitSignUp: (arg0: string) => void }) => {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState<IConsumerRegistration>({ showNewPass: false, showConfirmPass: false, recaptcha_key: '' });
  const { control, register, handleSubmit, formState: { errors, isSubmitted }, getValues, setValue, trigger } = useForm<FieldValues>({ defaultValues: { 'consumer[accepted_terms_and_conditions]': 1, action: 'consumer_registation' } });

  const watchPassword = useWatch({ name: 'consumer[password]', control });
  const watchPasswordConfirmation = useWatch({ control, name: 'consumer[password_confirmation]' });

  const handleChangeCountry = async (v: string) => {
    await axios
      .get(URL.CONSUMERS_REGISTRATIONS_COUNTRY_SPECIFIC_CONFIG, { params: { country_code: v } })
      .then(response => setState(prevState => ({ ...prevState, dob_year_range: response.data.dob_year_range })));

    setValue('consumer[date_of_birth(1i)]', '');
  };

  const handleSubmitSignUp = async (next: () => void) => {
    handleSubmit(async (f) => {
      setSubmitRequest(state.is_merchant_initiated, state.merchant_initiated_id as string, f, window.location.search)
        .then((response: AxiosResponse) => { onSubmitSignUp(response.data.sign_up_result); next(); })
        .catch(() => { setState(prevState => ({ ...prevState, refreshReCaptcha: !prevState.refreshReCaptcha })); });}
    )();
  };

  React.useEffect(() => {
    if (isSubmitted && Boolean(watchPasswordConfirmation) && watchPassword === watchPasswordConfirmation) trigger('consumer[password_confirmation]');
  }, [watchPassword]);

  useEffect(() => {
    axios
      .get(`${URL.CONSUMERS_REGISTRATIONS}/new`, { params: new URLSearchParams(window.location.search) })
      .then(response => {
        setState(prevState => ({ ...prevState, ...response.data }));

        if (response.data.is_merchant_initiated) {
          setValue('consumer[currency_id]', response.data.merchant_initiated_currency_id);
          setValue('consumer[email]', response.data.merchant_initiated_mail);
        }
      });
  }, []);

  return (
    <Form className="js-no-disable-submit-form">
      <Row>
        <Col xl={6}>
          <InputFormGroup
            name="consumer[email]"
            label={t('labels.email')}
            register={register}
            validate={{ isRequired, isValidEmail }}
            errors={errors}
            disabled={state.is_merchant_initiated}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <InputGroupFormGroup
            name="consumer[password]"
            label={t('labels.new_password')}
            register={register}
            validate={{ isRequired, isValidPassword }}
            errors={errors}
            onPaste={(e: SyntheticEvent) => e.preventDefault()}
            classNameGroup={'form-group-with-info-icon'}
            autoComplete="off"
            type={formUtils.toggleInputType(state.showNewPass)}
            className="border-right-0"
            appendLabel={(
              <FontAwesomeIcon
                icon={state.showNewPass ? faEyeSlash : faEye}
                size="lg"
                role="button"
                className="text-gray mx-2"
                onClick={() => setState({ ...state, showNewPass: !state.showNewPass })}
              />
            )}
            render={() => <PasswordIcons />}
          />
        </Col>
        <Col xl={6}>
          <InputGroupFormGroup
            name="consumer[password_confirmation]"
            register={register}
            validate={{ isRequired, isValidPassword, isEqualValues: v => isEqualValues(v, getValues('consumer[password]')) }}
            errors={errors}
            classNameGroup={'form-group-with-info-icon'}
            label={t('labels.confirm_new_password')}
            onPaste={(e: SyntheticEvent) => e.preventDefault()}
            autoComplete="off"
            type={formUtils.toggleInputType(state.showConfirmPass)}
            className="border-right-0"
            appendLabel={(
              <FontAwesomeIcon
                icon={state.showConfirmPass ? faEyeSlash : faEye}
                size="lg"
                role="button"
                className="text-gray mx-2"
                onClick={() => setState({ ...state, showConfirmPass: !state.showConfirmPass })}
              />
            )}
            render={() => <PasswordIcons />}
          />
        </Col>
        <Col xl={6}>
          <InputFormGroup
            name="consumer[first_name]"
            label={t('consumer.labels.first_name')}
            register={register}
            maxLength={50}
            validate={{ isRequired, isValidNameFormat }}
            errors={errors}
          />
        </Col>
        <Col xl={6}>
          <InputFormGroup
            name="consumer[last_name]"
            label={t('consumer.labels.last_name')}
            register={register}
            maxLength={50}
            validate={{ isRequired, isValidNameFormat }}
            errors={errors}
          />
        </Col>
        <Col xl={6}>
          <SelectFormGroup
            name="consumer[address_attributes][country_id]"
            label={t('labels.country')}
            register={register}
            options={state.countries}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => handleChangeCountry(e.target.options[e.target.selectedIndex].dataset.altValue as string)}
            validate={{ isSelectRequired }}
            errors={errors}
          />
        </Col>
        <Col xl={6}>
          <Form.Label className="required">{t('labels.date_of_birth')}</Form.Label>
          <Row>
            <Col xl={4}>
              <SelectFormGroup
                name="consumer[date_of_birth(3i)]"
                register={register}
                options={state.dob_day_range}
                disabled={setDobDisabled(getValues)}
                validate={{ isSelectRequired }}
                errors={errors}
              />
            </Col>
            <Col xl={4}>
              <SelectFormGroup
                name="consumer[date_of_birth(2i)]"
                register={register}
                disabled={setDobDisabled(getValues)}
                options={state.dob_month_range}
                validate={{ isSelectRequired }}
                errors={errors}
              />
            </Col>
            <Col xl={4}>
              <SelectFormGroup
                name="consumer[date_of_birth(1i)]"
                register={register}
                disabled={setDobDisabled(getValues)}
                options={state.dob_year_range}
                validate={{ isSelectRequired }}
                errors={errors}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <SelectFormGroup
            name="consumer[currency_id]"
            label={t('labels.wallet_currency')}
            disabled={state.is_merchant_initiated}
            register={register}
            options={state.currencies}
            validate={{ isSelectRequired }}
            errors={errors}
          />
        </Col>
      </Row>

      <If condition={state.recaptcha_key}>
        <Recaptcha sitekey={state.recaptcha_key} register={register} setValue={setValue} refreshReCaptcha={state.refreshReCaptcha} action="consumer_registration" />
      </If>

      <Card.Text
        dangerouslySetInnerHTML={{
          __html: t('labels.accepted_terms_and_conditions', {
            terms_and_conditions_link: `/${i18n.language}${URL.CONSUMERS_TERMS_AND_CONDITIONS}`,
            terms_of_use_link: `/${i18n.language}${URL.TERMS_OF_USE}`,
            privacy_policy_link: `/${i18n.language}${URL.PRIVACY_POLICY}`,
          }),
        }}
      />

      <Step.Next onClickNext={handleSubmitSignUp} className="float-right btn--next">
        <Loader url={[URL.CONSUMERS_REGISTRATIONS, `${URL.CONSUMERS_REGISTRATIONS}/${state.merchant_initiated_id}`]} className={'text-center loading--over loading--with-fog'} />
        {t('labels.register')}
      </Step.Next>
    </Form>
  );
};

export default ConsumerRegistrationForm;
