import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { publicImages } from 'bundles/utils';

const MerchantRegistrationThirdStep = () => {
  const { t, i18n } = useTranslation();

  return (
    <Card>
      <Card.Body>
        <div className="under-review-message text-center">
          <img alt="success" src={publicImages.success} />
          <h1 className="h2 mb-4">{t('titles.registration.under_review')}</h1>
          <Card.Text className="mb-5">{t('public.messages.success.congratulations') as string}</Card.Text>
          <a className="btn btn-primary" href={`/${i18n.language}`}>{t('public.labels.return_to_home')}</a>
        </div>
      </Card.Body>
    </Card>
  );
};

export default MerchantRegistrationThirdStep;
