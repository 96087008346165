import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';

import VerifySignInForm from 'bundles/components/public/verifySignIn/VerifySignInForm';
import { VerifySignInViewProps } from 'bundles/components/public/verifySignIn/types';
import If from 'bundles/components/common/If';

const VerifySignInView = (props: VerifySignInViewProps) => {
  const { t } = useTranslation();

  return (
    <div className="sign-in">
      <If condition={props.inline_message}>
        <div className="d-flex justify-content-center" dangerouslySetInnerHTML={{ __html: props.inline_message || '' }} />
      </If>

      <Card>
        <Card.Header as={'h1'} className="border-0">{t('labels.verify_your_login')}</Card.Header>
        <Card.Body>
          <VerifySignInForm {...props} />
        </Card.Body>
      </Card>
    </div>
  );
};

const VerifySignInViewWrapper: FC<VerifySignInViewProps> = (props) => <VerifySignInView {...props} />;

export default VerifySignInViewWrapper;
